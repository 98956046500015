import { createFileRoute, redirect } from '@tanstack/react-router'

import { exchangeToken } from '@/api/registration'
import { PageLoader } from '@/components/loader'
import { identifyUser } from '@/lib/mixpanel'

type Search = {
  token: string
}

export const Route = createFileRoute('/registration/token-exchange')({
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      token: search.token as string,
    }
  },
  loaderDeps: ({ search }) => search,
  loader: async ({ deps: { token } }) => {
    const response = await exchangeToken({ token })
    const userId = response?.data?.user_id

    if (!userId) {
      redirect({ to: '/login', throw: true })
    }

    identifyUser(`${userId}`)
    redirect({ to: '/account/debit-card', throw: true })
  },
  onError: () => {
    redirect({ to: '/login', throw: true })
  },
  pendingComponent: PageLoader,
})
