import {
  isValidPassword,
  PasswordInput,
  PasswordStrengthPopOver,
} from '@branch-messenger/willow-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { IconButton } from '@/components/button'
import { Box, FlexGrid } from '@/components/layout'
import { Loader } from '@/components/loader'
import { SVGIcon } from '@/components/svgIcon'
import { Title, Typography } from '@/components/typography'
import { useResetPassword } from '@/queries/reset-password'

interface Props {
  setStep: (step: number) => void
  OTP: string
  track_id: string
}

export type ResetPasswordFormValues = {
  password: string
  confirm_password: string
}

const resetPasswordFormInitialValues: ResetPasswordFormValues = {
  password: '',
  confirm_password: '',
}

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .test('password-strength', 'Password is not valid', value => {
      const { isValid } = isValidPassword(value)
      return isValid
    }),
  confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .test('password-strength', 'Passwords do not match', (value, context) => {
      return value === context.parent.password
    }),
})
export const ResetPasswordForm: FC<Props> = ({ setStep, OTP, track_id }) => {
  const resetPassword = useResetPassword()

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid, errors },
    setValue,
  } = useForm<ResetPasswordFormValues>({
    defaultValues: resetPasswordFormInitialValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: ResetPasswordFormValues) => {
    resetPassword.mutate(
      { ...values, otp: OTP, track_id: track_id },
      {
        onSuccess: () => setStep(2),
      }
    )
  }

  return (
    <Box direction="column" align="start">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Create your new password.</Title>
        <FlexGrid
          direction="column"
          align="start"
          size="lg"
          css={{
            width: 384,
            mt: '$24',
            '@mobile': {
              width: '100%',
            },
          }}
        >
          <PasswordStrengthPopOver
            trigger={
              <Box direction="column" align="start">
                <Typography color="hint" size="sm">
                  PASSWORD
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <PasswordInput
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
                <Typography color="alert" size="sm">
                  {errors.password?.message}
                </Typography>
              </Box>
            }
            password={watch('password')}
          />
          <Box direction="column" align="start">
            <Typography color="hint" size="sm">
              CONFIRM PASSWORD
            </Typography>
            <Controller
              name="confirm_password"
              control={control}
              render={({ field: { value, onBlur } }) => (
                <PasswordInput
                  onChange={e => {
                    setValue('confirm_password', e.target.value, {
                      shouldValidate: true,
                    })
                  }}
                  value={value}
                  onBlur={onBlur}
                />
              )}
            />
            <Typography color="alert" size="sm">
              {errors.confirm_password?.message}
            </Typography>
          </Box>
          <FlexGrid
            css={{
              backgroundColor: '$background',
              padding: '$16',
              br: '$md',
              mt: '$16',
            }}
            size="lg"
          >
            <div>
              <SVGIcon iconName="info" />
            </div>
            <Typography>
              Only fraudsters will tell you what password to use. Make sure your
              password is unique and only known to you.
            </Typography>
          </FlexGrid>
          <IconButton
            css={{ margin: '$16 0 $40 0', mr: 'auto' }}
            type="submit"
            iconRight
            disabled={!isValid || resetPassword.isPending}
          >
            <Typography>{isValid ? 'Continue' : 'Enter details'}</Typography>
            {resetPassword.isPending && <Loader />}
          </IconButton>
        </FlexGrid>
      </form>
    </Box>
  )
}
