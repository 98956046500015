import { createFileRoute } from '@tanstack/react-router'

import { Login } from '@/containers/Login'

type Search = {
  track_id?: string
  org_name?: string
  org_id?: string
}

export const Route = createFileRoute(`/login`)({
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      track_id: search.track_id as string,
      org_name: search.org_name as string,
      org_id: search.org_id as string,
    }
  },
  beforeLoad: ({ search, context: { auth } }) => {
    if (search.org_id) {
      auth.setOrgId(search.org_id)
    }
  },
  component: Login,
})
