import {
  CreateAccountRequestBody,
  DetectExistingAccountPayload,
  DetectExistingAccountResponse,
  ExchangeTokenPayload,
  ExchangeTokenResponse,
  GetTrackIdPayload,
  GetTrackIDResponse,
  LinkAccountsRequestBody,
  SearchRosterRequestBody,
  SearchRosterResponse,
  SendDeepLinkVerificationRequest,
  SignupResponse,
  TermsDetails,
  UpdateUserEmailBody,
  UpdateUserPhoneBody,
  VerificationPayload,
  VerificationResponse,
} from '@/types/registration'

import { branchHttp } from './branch-http'
import { http } from './http'

export const sendVerificationDeeplink = (
  orgId: string,
  data: SendDeepLinkVerificationRequest
) => http.post(`registration/organizations/${orgId}/send-deep-link`, data)

/**
 * This end-point is used to exchange a temporary token with a permanent track id.
 * The temporary token gets invalidated after retrieving the track id.
 * @param token exhange token to be use to get track id
 */
export const getTrackID = ({ orgId, token }: GetTrackIdPayload) =>
  http.get<GetTrackIDResponse>(
    `registration/organizations/${orgId}/exchange?token=${token}`
  )

export const verifyWorkerPasscode = ({
  orgId,
  ...requestBody
}: VerificationPayload) =>
  http.post<VerificationResponse>(
    `registration/organizations/${orgId}/verify-worker-passcode`,
    requestBody
  )

export const verifyOrgPasscode = ({
  orgId,
  ...requestBody
}: VerificationPayload) =>
  http.post<VerificationResponse>(
    `registration/organizations/${orgId}/verify-org-passcode`,
    requestBody
  )

/**
 * Verifies the validity of the email address, password strength, and the tracking ID before creating an entry in the database.
 * Returns a token after registration.
 * @param data Contains user credentials and track id
 */
export const createAccount = (orgId: string, data: CreateAccountRequestBody) =>
  http.post<SignupResponse>(`registration/organizations/${orgId}/signup`, data)

export const linkExistingAccount = (
  orgId: string,
  data: LinkAccountsRequestBody
) => http.put(`registration/organizations/${orgId}/signup`, data)

/**
 * Send OTP for user email address update
 */
export const updateAccountEmailOTP = () => http.post(`registration/email/otp`)

/**
 * Email address update
 */
export const updateAccountEmail = (data: UpdateUserEmailBody) =>
  http.put(`registration/email`, data)

/**
 * Send Email Deeplink for user phone update
 */
export const updateAccountPhoneDeeplink = () =>
  http.post(`registration/phone/deep-link`)

/**
 * Verify Email Deeplink for user phone update
 */
export const updateAccountPhoneVerifyEmail = () =>
  http.get(`verification/email/update-phone`)

/**
 * Send OTP for user phone update
 */
export const updateAccountPhoneOTP = (phone: string) =>
  http.post(`registration/phone/otp`, { phone })

/**
 * Phone update
 */
export const updateAccountPhone = (data: UpdateUserPhoneBody) =>
  http.put(`registration/phone`, data)

/**
 * Retrieves the terms and conditions that the user must accept prior to creating an account.
 */
export const getTerms = () =>
  branchHttp.get<TermsDetails[]>('p2/accounts/terms/direct/pre_user')

export const detectExistingAccount = ({
  org_id,
  ...requestPayload
}: DetectExistingAccountPayload) =>
  http.post<DetectExistingAccountResponse>(
    `/registration/organizations/${org_id}/detect-signup-method`,
    requestPayload
  )

export const searchRoster = (orgId: string, request: SearchRosterRequestBody) =>
  http.post<SearchRosterResponse>(
    `registration/organizations/${orgId}/roster/search`,
    request
  )

export const exchangeToken = (payload: ExchangeTokenPayload) =>
  http.post<ExchangeTokenResponse>('/exchange', payload)
