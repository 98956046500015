import { createFileRoute, retainSearchParams } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

import { VerifyCodeError } from '@/components/error-communications'
import { getTrackIdQueryOptions } from '@/queries/auth'

const schema = z.object({
  token: z.coerce.string().optional(),
})

export const exchangeSearchSchema = zodValidator(schema)
export type ExchangeSearchSchema = z.infer<typeof schema>

export const Route = createFileRoute(
  `/registration/organizations/$orgId/exchange`
)({
  validateSearch: exchangeSearchSchema,
  loaderDeps: ({ search }) => ({
    token: search.token,
  }),
  beforeLoad: ({ context, params }) => context.auth.setOrgId(params.orgId),
  loader: async ({ context: { queryClient }, deps: { token }, params }) => {
    queryClient.ensureQueryData(
      getTrackIdQueryOptions({ orgId: params.orgId, token: token! })
    )
  },
  search: { middlewares: [retainSearchParams(true)] },
  errorComponent: VerifyCodeError,
})
