import {
  Box,
  Button,
  FormItem,
  Input,
  isPossiblePhoneNumber,
  PhoneInputCountrySelect,
  Typography,
} from '@branch-messenger/willow-ui'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosError } from 'axios'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Loader } from '@/components/loader'
import { WillowTitle } from '@/components/typography'
import { DialogContext } from '@/context'
import { useSearchRoster } from '@/queries/registration/registration'
import { useOrg } from '@/selectors/useOrg'
import { OldIResponse } from '@/types/common'
import { SearchRosterRequestBody } from '@/types/registration'

import { useLinkCreation } from './LinkCreationContext'

const schema = yup
  .object()
  .shape({
    phone: yup
      .string()
      .nullable()
      .test({
        name: 'Phone is valid',
        message: 'Please enter a valid phone number',
        test: value => (value ? isPossiblePhoneNumber(value) : true),
      }),
    email: yup.string().nullable().email('Must be a valid email address'),
  })
  .test('phone-or-email', function (value) {
    if (!value.phone && !value.email) {
      return this.createError({
        path: 'phone',
        message: 'Either phone or email is required',
      })
    }
    return true
  })

export const PhoneEmailForm = () => {
  const { setCurrentStep } = useLinkCreation()

  const searchRoster = useSearchRoster()
  const { showAlert } = useContext(DialogContext)

  const { orgName } = useOrg()

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = (values: SearchRosterRequestBody) => {
    searchRoster.mutate(
      {
        email: values?.email || null,
        phone: values?.phone || null,
      },
      {
        onSuccess: () => setCurrentStep('VerificationMethod'),
        onError: error => {
          const status = (error as AxiosError<OldIResponse>).status
          if (status === 429) {
            showAlert({
              title: 'Limit Reached.',
              description: `You've reached the maximum number of attempts for today. Please try again after 24 hours.`,
              actionDescription: 'OK',
              hideCancel: true,
            })
          } else {
            showAlert({
              title: 'Info not found.',
              description:
                'Please try again or contact your organization for assistance.',
              actionDescription: 'OK',
              hideCancel: true,
            })
          }
        },
      }
    )
  }

  if (searchRoster.isPending) return <Loader size="lg" />

  return (
    <Box $display="flex" $direction="column" $align="start" $gap={8}>
      <Box $display="flex" $direction="column" $gap={4} $align="start">
        <WillowTitle>
          Enter the email and phone number on file with {orgName} to start
          opening your Branch account.
        </WillowTitle>
        <Typography>
          We’ll use these contact methods to confirm your workplace with{' '}
          {orgName}.
        </Typography>
      </Box>
      <Box
        $display="flex"
        $direction="column"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        $align="start"
        $gap={8}
        $fullWidth
      >
        <Box $display="flex" $direction="column" $gap={4} $fullWidth>
          <FormItem error={errors?.email?.message}>
            <Input
              placeholder="Enter Email"
              type="email"
              label="Email"
              {...register('email')}
            />
          </FormItem>
          <FormItem error={errors?.phone?.message}>
            <Controller
              name="phone"
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <PhoneInputCountrySelect
                  placeholder="Enter Phone"
                  label="Phone Number"
                  onChange={onChange}
                  value={value || ''}
                  onBlur={onBlur}
                />
              )}
            />
          </FormItem>
        </Box>
        <Button type="submit">Continue</Button>
      </Box>
    </Box>
  )
}
