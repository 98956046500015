import { NavigateOptions } from '@tanstack/react-router'

export type NavigationOption = {
  title: string
  route: NavigateOptions['to']
  show?: boolean
}

export const navigationOptions: NavigationOption[] = [
  {
    title: 'Account Info',
    route: '/account/management',
  },
  {
    title: 'Debit Card',
    route: '/account/debit-card',
  },
  { title: 'Branch App', route: '/account/additional-info' },
]
