import { FC, useState } from 'react'

import { useTerms } from '@/queries/registration/registration'

import { Terms } from './Terms'
import { TermsOverview } from './TermsOverview'

export const TermsAndPoliciesAgreement: FC = () => {
  const { data: terms = [] } = useTerms()
  const [step, setStep] = useState(0)

  switch (step) {
    case 0:
      return <TermsOverview setStep={setStep} />
    case 1:
      return <Terms terms={terms} />
    default:
      return <TermsOverview setStep={setStep} />
  }
}
