import { useSuspenseQuery } from '@tanstack/react-query'
import { FC, useState } from 'react'

import { CodeVerificationForm } from '@/components/verification/CodeVerificationForm'
import { workerInfoQueryOptions } from '@/queries/auth'
import {
  useUpdateAccountEmail,
  useUpdateAccountEmailOTP,
} from '@/queries/registration/registration'

import { AccountUpdateEmailSent } from './AccountUpdateEmailSent'
import { EmailUpdateForm } from './EmailUpdateForm'

export type EmailUpdateFormValues = {
  email: string
}

export const EmailUpdate: FC = () => {
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')
  const updateAccountEmailOTP = useUpdateAccountEmailOTP()
  const updateAccountEmail = useUpdateAccountEmail()
  const { data: workerInfo = [] } = useSuspenseQuery(workerInfoQueryOptions)

  const phoneLastFourDigits = workerInfo[0]?.phone_number
    ? workerInfo[0]?.phone_number.slice(-4)
    : ''

  const handleEmailUpdateSubmit = ({ email }: EmailUpdateFormValues) => {
    setEmail(email)
    updateAccountEmailOTP.mutate(undefined, {
      onSuccess: () => setStep(1),
    })
  }

  const resendCode = () => {
    updateAccountEmailOTP.mutate()
  }

  const confirmCode = (code: string) => {
    updateAccountEmail.mutate(
      {
        email,
        otp: code,
      },
      {
        onSuccess: () => setStep(2),
      }
    )
  }

  switch (step) {
    case 0:
      return (
        <EmailUpdateForm
          isPending={updateAccountEmailOTP.isPending}
          email={email}
          onSubmit={handleEmailUpdateSubmit}
        />
      )
    case 1:
      return (
        <CodeVerificationForm
          onResendCode={resendCode}
          onSubmit={confirmCode}
          verifyCodePending={updateAccountEmail.isPending}
          requestCodePending={updateAccountEmailOTP.isPending}
          phoneLastFourDigits={phoneLastFourDigits}
          verificationChannel="PHONE"
        />
      )
    case 2:
      return <AccountUpdateEmailSent email={email} />
    default:
      return (
        <EmailUpdateForm
          isPending={updateAccountEmailOTP.isPending}
          email={email}
          onSubmit={handleEmailUpdateSubmit}
        />
      )
  }
}
