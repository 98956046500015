/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as RequestPasswordResetImport } from './routes/request-password-reset'
import { Route as LoginImport } from './routes/login'
import { Route as DebitCardImport } from './routes/debit-card'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as SetupIndexImport } from './routes/setup/index'
import { Route as SetupTermsAgreementImport } from './routes/setup/terms-agreement'
import { Route as SetupPaymentSelectionImport } from './routes/setup/payment-selection'
import { Route as SetupPasswordCreationImport } from './routes/setup/password-creation'
import { Route as SetupIdentitySupportImport } from './routes/setup/identity-support'
import { Route as SetupEmailVerificationImport } from './routes/setup/email-verification'
import { Route as SetupCreateLinkImport } from './routes/setup/create-link'
import { Route as SetupCheckExistingAccountImport } from './routes/setup/check-existing-account'
import { Route as SetupBranchInformationalImport } from './routes/setup/branch-informational'
import { Route as SetupAdditionalInfoImport } from './routes/setup/additional-info'
import { Route as RegistrationTokenExchangeImport } from './routes/registration/token-exchange'
import { Route as AuthenticatedAccountIndexImport } from './routes/_authenticated/account/index'
import { Route as RegistrationPasswordExchangeImport } from './routes/registration/password.exchange'
import { Route as AuthenticatedAccountPhoneUpdateImport } from './routes/_authenticated/account/phone-update'
import { Route as AuthenticatedAccountManagementImport } from './routes/_authenticated/account/management'
import { Route as AuthenticatedAccountInstantPayDisabledImport } from './routes/_authenticated/account/instant-pay-disabled'
import { Route as AuthenticatedAccountEmailUpdateImport } from './routes/_authenticated/account/email-update'
import { Route as AuthenticatedAccountDebitCardImport } from './routes/_authenticated/account/debit-card'
import { Route as AuthenticatedAccountAdditionalInfoImport } from './routes/_authenticated/account/additional-info'
import { Route as RegistrationOrganizationsOrgIdExchangeRouteImport } from './routes/registration/organizations.$orgId.exchange.route'
import { Route as RegistrationOrganizationsOrgIdExchangeIndexImport } from './routes/registration/organizations.$orgId.exchange.index'

// Create Virtual Routes

const RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyImport =
  createFileRoute(
    '/registration/organizations/$orgId/exchange/workerPasscode',
  )()
const RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyImport =
  createFileRoute('/registration/organizations/$orgId/exchange/orgPasscode')()

// Create/Update Routes

const RequestPasswordResetRoute = RequestPasswordResetImport.update({
  id: '/request-password-reset',
  path: '/request-password-reset',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const DebitCardRoute = DebitCardImport.update({
  id: '/debit-card',
  path: '/debit-card',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SetupIndexRoute = SetupIndexImport.update({
  id: '/setup/',
  path: '/setup/',
  getParentRoute: () => rootRoute,
} as any)

const SetupTermsAgreementRoute = SetupTermsAgreementImport.update({
  id: '/setup/terms-agreement',
  path: '/setup/terms-agreement',
  getParentRoute: () => rootRoute,
} as any)

const SetupPaymentSelectionRoute = SetupPaymentSelectionImport.update({
  id: '/setup/payment-selection',
  path: '/setup/payment-selection',
  getParentRoute: () => rootRoute,
} as any)

const SetupPasswordCreationRoute = SetupPasswordCreationImport.update({
  id: '/setup/password-creation',
  path: '/setup/password-creation',
  getParentRoute: () => rootRoute,
} as any)

const SetupIdentitySupportRoute = SetupIdentitySupportImport.update({
  id: '/setup/identity-support',
  path: '/setup/identity-support',
  getParentRoute: () => rootRoute,
} as any)

const SetupEmailVerificationRoute = SetupEmailVerificationImport.update({
  id: '/setup/email-verification',
  path: '/setup/email-verification',
  getParentRoute: () => rootRoute,
} as any)

const SetupCreateLinkRoute = SetupCreateLinkImport.update({
  id: '/setup/create-link',
  path: '/setup/create-link',
  getParentRoute: () => rootRoute,
} as any)

const SetupCheckExistingAccountRoute = SetupCheckExistingAccountImport.update({
  id: '/setup/check-existing-account',
  path: '/setup/check-existing-account',
  getParentRoute: () => rootRoute,
} as any)

const SetupBranchInformationalRoute = SetupBranchInformationalImport.update({
  id: '/setup/branch-informational',
  path: '/setup/branch-informational',
  getParentRoute: () => rootRoute,
} as any)

const SetupAdditionalInfoRoute = SetupAdditionalInfoImport.update({
  id: '/setup/additional-info',
  path: '/setup/additional-info',
  getParentRoute: () => rootRoute,
} as any)

const RegistrationTokenExchangeRoute = RegistrationTokenExchangeImport.update({
  id: '/registration/token-exchange',
  path: '/registration/token-exchange',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedAccountIndexRoute = AuthenticatedAccountIndexImport.update({
  id: '/account/',
  path: '/account/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const RegistrationPasswordExchangeRoute =
  RegistrationPasswordExchangeImport.update({
    id: '/registration/password/exchange',
    path: '/registration/password/exchange',
    getParentRoute: () => rootRoute,
  } as any)

const AuthenticatedAccountPhoneUpdateRoute =
  AuthenticatedAccountPhoneUpdateImport.update({
    id: '/account/phone-update',
    path: '/account/phone-update',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedAccountManagementRoute =
  AuthenticatedAccountManagementImport.update({
    id: '/account/management',
    path: '/account/management',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedAccountInstantPayDisabledRoute =
  AuthenticatedAccountInstantPayDisabledImport.update({
    id: '/account/instant-pay-disabled',
    path: '/account/instant-pay-disabled',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedAccountEmailUpdateRoute =
  AuthenticatedAccountEmailUpdateImport.update({
    id: '/account/email-update',
    path: '/account/email-update',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedAccountDebitCardRoute =
  AuthenticatedAccountDebitCardImport.update({
    id: '/account/debit-card',
    path: '/account/debit-card',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const AuthenticatedAccountAdditionalInfoRoute =
  AuthenticatedAccountAdditionalInfoImport.update({
    id: '/account/additional-info',
    path: '/account/additional-info',
    getParentRoute: () => AuthenticatedRoute,
  } as any)

const RegistrationOrganizationsOrgIdExchangeRouteRoute =
  RegistrationOrganizationsOrgIdExchangeRouteImport.update({
    id: '/registration/organizations/$orgId/exchange',
    path: '/registration/organizations/$orgId/exchange',
    getParentRoute: () => rootRoute,
  } as any)

const RegistrationOrganizationsOrgIdExchangeIndexRoute =
  RegistrationOrganizationsOrgIdExchangeIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => RegistrationOrganizationsOrgIdExchangeRouteRoute,
  } as any)

const RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyRoute =
  RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyImport.update({
    id: '/workerPasscode',
    path: '/workerPasscode',
    getParentRoute: () => RegistrationOrganizationsOrgIdExchangeRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/registration/organizations.$orgId.exchange.workerPasscode.lazy'
    ).then((d) => d.Route),
  )

const RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyRoute =
  RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyImport.update({
    id: '/orgPasscode',
    path: '/orgPasscode',
    getParentRoute: () => RegistrationOrganizationsOrgIdExchangeRouteRoute,
  } as any).lazy(() =>
    import(
      './routes/registration/organizations.$orgId.exchange.orgPasscode.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/debit-card': {
      id: '/debit-card'
      path: '/debit-card'
      fullPath: '/debit-card'
      preLoaderRoute: typeof DebitCardImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/request-password-reset': {
      id: '/request-password-reset'
      path: '/request-password-reset'
      fullPath: '/request-password-reset'
      preLoaderRoute: typeof RequestPasswordResetImport
      parentRoute: typeof rootRoute
    }
    '/registration/token-exchange': {
      id: '/registration/token-exchange'
      path: '/registration/token-exchange'
      fullPath: '/registration/token-exchange'
      preLoaderRoute: typeof RegistrationTokenExchangeImport
      parentRoute: typeof rootRoute
    }
    '/setup/additional-info': {
      id: '/setup/additional-info'
      path: '/setup/additional-info'
      fullPath: '/setup/additional-info'
      preLoaderRoute: typeof SetupAdditionalInfoImport
      parentRoute: typeof rootRoute
    }
    '/setup/branch-informational': {
      id: '/setup/branch-informational'
      path: '/setup/branch-informational'
      fullPath: '/setup/branch-informational'
      preLoaderRoute: typeof SetupBranchInformationalImport
      parentRoute: typeof rootRoute
    }
    '/setup/check-existing-account': {
      id: '/setup/check-existing-account'
      path: '/setup/check-existing-account'
      fullPath: '/setup/check-existing-account'
      preLoaderRoute: typeof SetupCheckExistingAccountImport
      parentRoute: typeof rootRoute
    }
    '/setup/create-link': {
      id: '/setup/create-link'
      path: '/setup/create-link'
      fullPath: '/setup/create-link'
      preLoaderRoute: typeof SetupCreateLinkImport
      parentRoute: typeof rootRoute
    }
    '/setup/email-verification': {
      id: '/setup/email-verification'
      path: '/setup/email-verification'
      fullPath: '/setup/email-verification'
      preLoaderRoute: typeof SetupEmailVerificationImport
      parentRoute: typeof rootRoute
    }
    '/setup/identity-support': {
      id: '/setup/identity-support'
      path: '/setup/identity-support'
      fullPath: '/setup/identity-support'
      preLoaderRoute: typeof SetupIdentitySupportImport
      parentRoute: typeof rootRoute
    }
    '/setup/password-creation': {
      id: '/setup/password-creation'
      path: '/setup/password-creation'
      fullPath: '/setup/password-creation'
      preLoaderRoute: typeof SetupPasswordCreationImport
      parentRoute: typeof rootRoute
    }
    '/setup/payment-selection': {
      id: '/setup/payment-selection'
      path: '/setup/payment-selection'
      fullPath: '/setup/payment-selection'
      preLoaderRoute: typeof SetupPaymentSelectionImport
      parentRoute: typeof rootRoute
    }
    '/setup/terms-agreement': {
      id: '/setup/terms-agreement'
      path: '/setup/terms-agreement'
      fullPath: '/setup/terms-agreement'
      preLoaderRoute: typeof SetupTermsAgreementImport
      parentRoute: typeof rootRoute
    }
    '/setup/': {
      id: '/setup/'
      path: '/setup'
      fullPath: '/setup'
      preLoaderRoute: typeof SetupIndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/account/additional-info': {
      id: '/_authenticated/account/additional-info'
      path: '/account/additional-info'
      fullPath: '/account/additional-info'
      preLoaderRoute: typeof AuthenticatedAccountAdditionalInfoImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/account/debit-card': {
      id: '/_authenticated/account/debit-card'
      path: '/account/debit-card'
      fullPath: '/account/debit-card'
      preLoaderRoute: typeof AuthenticatedAccountDebitCardImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/account/email-update': {
      id: '/_authenticated/account/email-update'
      path: '/account/email-update'
      fullPath: '/account/email-update'
      preLoaderRoute: typeof AuthenticatedAccountEmailUpdateImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/account/instant-pay-disabled': {
      id: '/_authenticated/account/instant-pay-disabled'
      path: '/account/instant-pay-disabled'
      fullPath: '/account/instant-pay-disabled'
      preLoaderRoute: typeof AuthenticatedAccountInstantPayDisabledImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/account/management': {
      id: '/_authenticated/account/management'
      path: '/account/management'
      fullPath: '/account/management'
      preLoaderRoute: typeof AuthenticatedAccountManagementImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/account/phone-update': {
      id: '/_authenticated/account/phone-update'
      path: '/account/phone-update'
      fullPath: '/account/phone-update'
      preLoaderRoute: typeof AuthenticatedAccountPhoneUpdateImport
      parentRoute: typeof AuthenticatedImport
    }
    '/registration/password/exchange': {
      id: '/registration/password/exchange'
      path: '/registration/password/exchange'
      fullPath: '/registration/password/exchange'
      preLoaderRoute: typeof RegistrationPasswordExchangeImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/account/': {
      id: '/_authenticated/account/'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AuthenticatedAccountIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/registration/organizations/$orgId/exchange': {
      id: '/registration/organizations/$orgId/exchange'
      path: '/registration/organizations/$orgId/exchange'
      fullPath: '/registration/organizations/$orgId/exchange'
      preLoaderRoute: typeof RegistrationOrganizationsOrgIdExchangeRouteImport
      parentRoute: typeof rootRoute
    }
    '/registration/organizations/$orgId/exchange/orgPasscode': {
      id: '/registration/organizations/$orgId/exchange/orgPasscode'
      path: '/orgPasscode'
      fullPath: '/registration/organizations/$orgId/exchange/orgPasscode'
      preLoaderRoute: typeof RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyImport
      parentRoute: typeof RegistrationOrganizationsOrgIdExchangeRouteImport
    }
    '/registration/organizations/$orgId/exchange/workerPasscode': {
      id: '/registration/organizations/$orgId/exchange/workerPasscode'
      path: '/workerPasscode'
      fullPath: '/registration/organizations/$orgId/exchange/workerPasscode'
      preLoaderRoute: typeof RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyImport
      parentRoute: typeof RegistrationOrganizationsOrgIdExchangeRouteImport
    }
    '/registration/organizations/$orgId/exchange/': {
      id: '/registration/organizations/$orgId/exchange/'
      path: '/'
      fullPath: '/registration/organizations/$orgId/exchange/'
      preLoaderRoute: typeof RegistrationOrganizationsOrgIdExchangeIndexImport
      parentRoute: typeof RegistrationOrganizationsOrgIdExchangeRouteImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedAccountAdditionalInfoRoute: typeof AuthenticatedAccountAdditionalInfoRoute
  AuthenticatedAccountDebitCardRoute: typeof AuthenticatedAccountDebitCardRoute
  AuthenticatedAccountEmailUpdateRoute: typeof AuthenticatedAccountEmailUpdateRoute
  AuthenticatedAccountInstantPayDisabledRoute: typeof AuthenticatedAccountInstantPayDisabledRoute
  AuthenticatedAccountManagementRoute: typeof AuthenticatedAccountManagementRoute
  AuthenticatedAccountPhoneUpdateRoute: typeof AuthenticatedAccountPhoneUpdateRoute
  AuthenticatedAccountIndexRoute: typeof AuthenticatedAccountIndexRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedAccountAdditionalInfoRoute:
    AuthenticatedAccountAdditionalInfoRoute,
  AuthenticatedAccountDebitCardRoute: AuthenticatedAccountDebitCardRoute,
  AuthenticatedAccountEmailUpdateRoute: AuthenticatedAccountEmailUpdateRoute,
  AuthenticatedAccountInstantPayDisabledRoute:
    AuthenticatedAccountInstantPayDisabledRoute,
  AuthenticatedAccountManagementRoute: AuthenticatedAccountManagementRoute,
  AuthenticatedAccountPhoneUpdateRoute: AuthenticatedAccountPhoneUpdateRoute,
  AuthenticatedAccountIndexRoute: AuthenticatedAccountIndexRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface RegistrationOrganizationsOrgIdExchangeRouteRouteChildren {
  RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyRoute: typeof RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyRoute
  RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyRoute: typeof RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyRoute
  RegistrationOrganizationsOrgIdExchangeIndexRoute: typeof RegistrationOrganizationsOrgIdExchangeIndexRoute
}

const RegistrationOrganizationsOrgIdExchangeRouteRouteChildren: RegistrationOrganizationsOrgIdExchangeRouteRouteChildren =
  {
    RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyRoute:
      RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyRoute,
    RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyRoute:
      RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyRoute,
    RegistrationOrganizationsOrgIdExchangeIndexRoute:
      RegistrationOrganizationsOrgIdExchangeIndexRoute,
  }

const RegistrationOrganizationsOrgIdExchangeRouteRouteWithChildren =
  RegistrationOrganizationsOrgIdExchangeRouteRoute._addFileChildren(
    RegistrationOrganizationsOrgIdExchangeRouteRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/debit-card': typeof DebitCardRoute
  '/login': typeof LoginRoute
  '/request-password-reset': typeof RequestPasswordResetRoute
  '/registration/token-exchange': typeof RegistrationTokenExchangeRoute
  '/setup/additional-info': typeof SetupAdditionalInfoRoute
  '/setup/branch-informational': typeof SetupBranchInformationalRoute
  '/setup/check-existing-account': typeof SetupCheckExistingAccountRoute
  '/setup/create-link': typeof SetupCreateLinkRoute
  '/setup/email-verification': typeof SetupEmailVerificationRoute
  '/setup/identity-support': typeof SetupIdentitySupportRoute
  '/setup/password-creation': typeof SetupPasswordCreationRoute
  '/setup/payment-selection': typeof SetupPaymentSelectionRoute
  '/setup/terms-agreement': typeof SetupTermsAgreementRoute
  '/setup': typeof SetupIndexRoute
  '/account/additional-info': typeof AuthenticatedAccountAdditionalInfoRoute
  '/account/debit-card': typeof AuthenticatedAccountDebitCardRoute
  '/account/email-update': typeof AuthenticatedAccountEmailUpdateRoute
  '/account/instant-pay-disabled': typeof AuthenticatedAccountInstantPayDisabledRoute
  '/account/management': typeof AuthenticatedAccountManagementRoute
  '/account/phone-update': typeof AuthenticatedAccountPhoneUpdateRoute
  '/registration/password/exchange': typeof RegistrationPasswordExchangeRoute
  '/account': typeof AuthenticatedAccountIndexRoute
  '/registration/organizations/$orgId/exchange': typeof RegistrationOrganizationsOrgIdExchangeRouteRouteWithChildren
  '/registration/organizations/$orgId/exchange/orgPasscode': typeof RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyRoute
  '/registration/organizations/$orgId/exchange/workerPasscode': typeof RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyRoute
  '/registration/organizations/$orgId/exchange/': typeof RegistrationOrganizationsOrgIdExchangeIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedRouteWithChildren
  '/debit-card': typeof DebitCardRoute
  '/login': typeof LoginRoute
  '/request-password-reset': typeof RequestPasswordResetRoute
  '/registration/token-exchange': typeof RegistrationTokenExchangeRoute
  '/setup/additional-info': typeof SetupAdditionalInfoRoute
  '/setup/branch-informational': typeof SetupBranchInformationalRoute
  '/setup/check-existing-account': typeof SetupCheckExistingAccountRoute
  '/setup/create-link': typeof SetupCreateLinkRoute
  '/setup/email-verification': typeof SetupEmailVerificationRoute
  '/setup/identity-support': typeof SetupIdentitySupportRoute
  '/setup/password-creation': typeof SetupPasswordCreationRoute
  '/setup/payment-selection': typeof SetupPaymentSelectionRoute
  '/setup/terms-agreement': typeof SetupTermsAgreementRoute
  '/setup': typeof SetupIndexRoute
  '/account/additional-info': typeof AuthenticatedAccountAdditionalInfoRoute
  '/account/debit-card': typeof AuthenticatedAccountDebitCardRoute
  '/account/email-update': typeof AuthenticatedAccountEmailUpdateRoute
  '/account/instant-pay-disabled': typeof AuthenticatedAccountInstantPayDisabledRoute
  '/account/management': typeof AuthenticatedAccountManagementRoute
  '/account/phone-update': typeof AuthenticatedAccountPhoneUpdateRoute
  '/registration/password/exchange': typeof RegistrationPasswordExchangeRoute
  '/account': typeof AuthenticatedAccountIndexRoute
  '/registration/organizations/$orgId/exchange/orgPasscode': typeof RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyRoute
  '/registration/organizations/$orgId/exchange/workerPasscode': typeof RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyRoute
  '/registration/organizations/$orgId/exchange': typeof RegistrationOrganizationsOrgIdExchangeIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/debit-card': typeof DebitCardRoute
  '/login': typeof LoginRoute
  '/request-password-reset': typeof RequestPasswordResetRoute
  '/registration/token-exchange': typeof RegistrationTokenExchangeRoute
  '/setup/additional-info': typeof SetupAdditionalInfoRoute
  '/setup/branch-informational': typeof SetupBranchInformationalRoute
  '/setup/check-existing-account': typeof SetupCheckExistingAccountRoute
  '/setup/create-link': typeof SetupCreateLinkRoute
  '/setup/email-verification': typeof SetupEmailVerificationRoute
  '/setup/identity-support': typeof SetupIdentitySupportRoute
  '/setup/password-creation': typeof SetupPasswordCreationRoute
  '/setup/payment-selection': typeof SetupPaymentSelectionRoute
  '/setup/terms-agreement': typeof SetupTermsAgreementRoute
  '/setup/': typeof SetupIndexRoute
  '/_authenticated/account/additional-info': typeof AuthenticatedAccountAdditionalInfoRoute
  '/_authenticated/account/debit-card': typeof AuthenticatedAccountDebitCardRoute
  '/_authenticated/account/email-update': typeof AuthenticatedAccountEmailUpdateRoute
  '/_authenticated/account/instant-pay-disabled': typeof AuthenticatedAccountInstantPayDisabledRoute
  '/_authenticated/account/management': typeof AuthenticatedAccountManagementRoute
  '/_authenticated/account/phone-update': typeof AuthenticatedAccountPhoneUpdateRoute
  '/registration/password/exchange': typeof RegistrationPasswordExchangeRoute
  '/_authenticated/account/': typeof AuthenticatedAccountIndexRoute
  '/registration/organizations/$orgId/exchange': typeof RegistrationOrganizationsOrgIdExchangeRouteRouteWithChildren
  '/registration/organizations/$orgId/exchange/orgPasscode': typeof RegistrationOrganizationsOrgIdExchangeOrgPasscodeLazyRoute
  '/registration/organizations/$orgId/exchange/workerPasscode': typeof RegistrationOrganizationsOrgIdExchangeWorkerPasscodeLazyRoute
  '/registration/organizations/$orgId/exchange/': typeof RegistrationOrganizationsOrgIdExchangeIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/debit-card'
    | '/login'
    | '/request-password-reset'
    | '/registration/token-exchange'
    | '/setup/additional-info'
    | '/setup/branch-informational'
    | '/setup/check-existing-account'
    | '/setup/create-link'
    | '/setup/email-verification'
    | '/setup/identity-support'
    | '/setup/password-creation'
    | '/setup/payment-selection'
    | '/setup/terms-agreement'
    | '/setup'
    | '/account/additional-info'
    | '/account/debit-card'
    | '/account/email-update'
    | '/account/instant-pay-disabled'
    | '/account/management'
    | '/account/phone-update'
    | '/registration/password/exchange'
    | '/account'
    | '/registration/organizations/$orgId/exchange'
    | '/registration/organizations/$orgId/exchange/orgPasscode'
    | '/registration/organizations/$orgId/exchange/workerPasscode'
    | '/registration/organizations/$orgId/exchange/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/debit-card'
    | '/login'
    | '/request-password-reset'
    | '/registration/token-exchange'
    | '/setup/additional-info'
    | '/setup/branch-informational'
    | '/setup/check-existing-account'
    | '/setup/create-link'
    | '/setup/email-verification'
    | '/setup/identity-support'
    | '/setup/password-creation'
    | '/setup/payment-selection'
    | '/setup/terms-agreement'
    | '/setup'
    | '/account/additional-info'
    | '/account/debit-card'
    | '/account/email-update'
    | '/account/instant-pay-disabled'
    | '/account/management'
    | '/account/phone-update'
    | '/registration/password/exchange'
    | '/account'
    | '/registration/organizations/$orgId/exchange/orgPasscode'
    | '/registration/organizations/$orgId/exchange/workerPasscode'
    | '/registration/organizations/$orgId/exchange'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/debit-card'
    | '/login'
    | '/request-password-reset'
    | '/registration/token-exchange'
    | '/setup/additional-info'
    | '/setup/branch-informational'
    | '/setup/check-existing-account'
    | '/setup/create-link'
    | '/setup/email-verification'
    | '/setup/identity-support'
    | '/setup/password-creation'
    | '/setup/payment-selection'
    | '/setup/terms-agreement'
    | '/setup/'
    | '/_authenticated/account/additional-info'
    | '/_authenticated/account/debit-card'
    | '/_authenticated/account/email-update'
    | '/_authenticated/account/instant-pay-disabled'
    | '/_authenticated/account/management'
    | '/_authenticated/account/phone-update'
    | '/registration/password/exchange'
    | '/_authenticated/account/'
    | '/registration/organizations/$orgId/exchange'
    | '/registration/organizations/$orgId/exchange/orgPasscode'
    | '/registration/organizations/$orgId/exchange/workerPasscode'
    | '/registration/organizations/$orgId/exchange/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  DebitCardRoute: typeof DebitCardRoute
  LoginRoute: typeof LoginRoute
  RequestPasswordResetRoute: typeof RequestPasswordResetRoute
  RegistrationTokenExchangeRoute: typeof RegistrationTokenExchangeRoute
  SetupAdditionalInfoRoute: typeof SetupAdditionalInfoRoute
  SetupBranchInformationalRoute: typeof SetupBranchInformationalRoute
  SetupCheckExistingAccountRoute: typeof SetupCheckExistingAccountRoute
  SetupCreateLinkRoute: typeof SetupCreateLinkRoute
  SetupEmailVerificationRoute: typeof SetupEmailVerificationRoute
  SetupIdentitySupportRoute: typeof SetupIdentitySupportRoute
  SetupPasswordCreationRoute: typeof SetupPasswordCreationRoute
  SetupPaymentSelectionRoute: typeof SetupPaymentSelectionRoute
  SetupTermsAgreementRoute: typeof SetupTermsAgreementRoute
  SetupIndexRoute: typeof SetupIndexRoute
  RegistrationPasswordExchangeRoute: typeof RegistrationPasswordExchangeRoute
  RegistrationOrganizationsOrgIdExchangeRouteRoute: typeof RegistrationOrganizationsOrgIdExchangeRouteRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  DebitCardRoute: DebitCardRoute,
  LoginRoute: LoginRoute,
  RequestPasswordResetRoute: RequestPasswordResetRoute,
  RegistrationTokenExchangeRoute: RegistrationTokenExchangeRoute,
  SetupAdditionalInfoRoute: SetupAdditionalInfoRoute,
  SetupBranchInformationalRoute: SetupBranchInformationalRoute,
  SetupCheckExistingAccountRoute: SetupCheckExistingAccountRoute,
  SetupCreateLinkRoute: SetupCreateLinkRoute,
  SetupEmailVerificationRoute: SetupEmailVerificationRoute,
  SetupIdentitySupportRoute: SetupIdentitySupportRoute,
  SetupPasswordCreationRoute: SetupPasswordCreationRoute,
  SetupPaymentSelectionRoute: SetupPaymentSelectionRoute,
  SetupTermsAgreementRoute: SetupTermsAgreementRoute,
  SetupIndexRoute: SetupIndexRoute,
  RegistrationPasswordExchangeRoute: RegistrationPasswordExchangeRoute,
  RegistrationOrganizationsOrgIdExchangeRouteRoute:
    RegistrationOrganizationsOrgIdExchangeRouteRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/debit-card",
        "/login",
        "/request-password-reset",
        "/registration/token-exchange",
        "/setup/additional-info",
        "/setup/branch-informational",
        "/setup/check-existing-account",
        "/setup/create-link",
        "/setup/email-verification",
        "/setup/identity-support",
        "/setup/password-creation",
        "/setup/payment-selection",
        "/setup/terms-agreement",
        "/setup/",
        "/registration/password/exchange",
        "/registration/organizations/$orgId/exchange"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/account/additional-info",
        "/_authenticated/account/debit-card",
        "/_authenticated/account/email-update",
        "/_authenticated/account/instant-pay-disabled",
        "/_authenticated/account/management",
        "/_authenticated/account/phone-update",
        "/_authenticated/account/"
      ]
    },
    "/debit-card": {
      "filePath": "debit-card.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/request-password-reset": {
      "filePath": "request-password-reset.tsx"
    },
    "/registration/token-exchange": {
      "filePath": "registration/token-exchange.tsx"
    },
    "/setup/additional-info": {
      "filePath": "setup/additional-info.tsx"
    },
    "/setup/branch-informational": {
      "filePath": "setup/branch-informational.tsx"
    },
    "/setup/check-existing-account": {
      "filePath": "setup/check-existing-account.tsx"
    },
    "/setup/create-link": {
      "filePath": "setup/create-link.tsx"
    },
    "/setup/email-verification": {
      "filePath": "setup/email-verification.tsx"
    },
    "/setup/identity-support": {
      "filePath": "setup/identity-support.tsx"
    },
    "/setup/password-creation": {
      "filePath": "setup/password-creation.tsx"
    },
    "/setup/payment-selection": {
      "filePath": "setup/payment-selection.tsx"
    },
    "/setup/terms-agreement": {
      "filePath": "setup/terms-agreement.tsx"
    },
    "/setup/": {
      "filePath": "setup/index.tsx"
    },
    "/_authenticated/account/additional-info": {
      "filePath": "_authenticated/account/additional-info.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/account/debit-card": {
      "filePath": "_authenticated/account/debit-card.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/account/email-update": {
      "filePath": "_authenticated/account/email-update.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/account/instant-pay-disabled": {
      "filePath": "_authenticated/account/instant-pay-disabled.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/account/management": {
      "filePath": "_authenticated/account/management.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/account/phone-update": {
      "filePath": "_authenticated/account/phone-update.tsx",
      "parent": "/_authenticated"
    },
    "/registration/password/exchange": {
      "filePath": "registration/password.exchange.tsx"
    },
    "/_authenticated/account/": {
      "filePath": "_authenticated/account/index.tsx",
      "parent": "/_authenticated"
    },
    "/registration/organizations/$orgId/exchange": {
      "filePath": "registration/organizations.$orgId.exchange.route.ts",
      "children": [
        "/registration/organizations/$orgId/exchange/orgPasscode",
        "/registration/organizations/$orgId/exchange/workerPasscode",
        "/registration/organizations/$orgId/exchange/"
      ]
    },
    "/registration/organizations/$orgId/exchange/orgPasscode": {
      "filePath": "registration/organizations.$orgId.exchange.orgPasscode.lazy.ts",
      "parent": "/registration/organizations/$orgId/exchange"
    },
    "/registration/organizations/$orgId/exchange/workerPasscode": {
      "filePath": "registration/organizations.$orgId.exchange.workerPasscode.lazy.ts",
      "parent": "/registration/organizations/$orgId/exchange"
    },
    "/registration/organizations/$orgId/exchange/": {
      "filePath": "registration/organizations.$orgId.exchange.index.ts",
      "parent": "/registration/organizations/$orgId/exchange"
    }
  }
}
ROUTE_MANIFEST_END */
