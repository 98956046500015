import { createFileRoute, redirect } from '@tanstack/react-router'

import { getTrackIdQueryOptions } from '@/queries/auth'

export const Route = createFileRoute(
  '/registration/organizations/$orgId/exchange/'
)({
  loaderDeps: ({ search }) => ({
    token: search.token,
  }),
  loader: async ({ context: { queryClient }, deps: { token }, params }) => {
    const { org_passcode_required, worker_passcode_required, track_id } =
      await queryClient.ensureQueryData(
        getTrackIdQueryOptions({ orgId: params.orgId, token: token! })
      )
    if (!org_passcode_required && !worker_passcode_required) {
      redirect({
        to: '/setup/check-existing-account',
        search: { track_id },
        throw: true,
      })
    }
    if (org_passcode_required) {
      redirect({
        to: '/registration/organizations/$orgId/exchange/orgPasscode',
        params: { orgId: params.orgId },
        throw: true,
      })
    }
    redirect({
      to: '/registration/organizations/$orgId/exchange/workerPasscode',
      params: { orgId: params.orgId },
      throw: true,
    })
  },
})
