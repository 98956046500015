import {
  Box,
  Button,
  CardRadioItem,
  CardRadioRoot,
  Icon,
  IconButton,
  Typography,
} from '@branch-messenger/willow-ui'
import {
  ArrowLeft,
  Mail01,
  MessageDotsCircle,
} from '@branch-messenger/willow-ui/icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Loader } from '@/components/loader'
import {
  useSearchRosterMutationState,
  useSendVerificationDeeplink,
} from '@/queries/registration/registration'
import { InputType } from '@/types/registration'

import { useLinkCreation } from './LinkCreationContext'

const schema = yup.object().shape({
  verificationMethod: yup
    .mixed<InputType>()
    .oneOf(Object.values(InputType), 'Please select a verification method')
    .required('Verification method is required'),
})

type FormValues = {
  verificationMethod: InputType
}

export const VerificationMethod = () => {
  const { setCurrentStep, setChosenVerificationMethod } = useLinkCreation()
  const { mutate: sendDeepLink, isPending } = useSendVerificationDeeplink()

  const searchRoster = useSearchRosterMutationState()

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: FormValues) => {
    setChosenVerificationMethod(data.verificationMethod)
    sendDeepLink(
      {
        signup_method: data.verificationMethod,
        track_id: searchRoster?.track_id,
      },
      {
        onSuccess: () => setCurrentStep('LinkCreated'),
      }
    )
  }

  const backStep = () => {
    setCurrentStep('PhoneEmailForm')
  }

  const verifiedPhoneLastFour = searchRoster?.phone
  const verifiedEmail = searchRoster?.email

  if (isPending) return <Loader size="lg" />

  return (
    <Box
      as="form"
      $display="flex"
      $direction="column"
      $align="start"
      $gap={8}
      onSubmit={handleSubmit(onSubmit)}
    >
      <IconButton onClick={backStep}>
        <Icon Icon={ArrowLeft} size={6} />
      </IconButton>
      <Typography $bold $size="3xl">
        Select how you’d like to verify it’s you.
      </Typography>
      <Typography>
        To keep your account secure, please select a verification method below.
        This helps us verify your identity and keep your information secure.
      </Typography>
      <Controller
        name="verificationMethod"
        control={control}
        render={({ field }) => (
          <CardRadioRoot
            {...field}
            onValueChange={field.onChange}
            style={{ width: '100%' }}
          >
            {verifiedEmail && (
              <CardRadioItem
                Icon={Mail01}
                title="Verify by email"
                subtitle="We’ll send a code to your email address on file:"
                value={InputType.EMAIL}
              >
                <Typography $size="sm" $bold>
                  {verifiedEmail}
                </Typography>
              </CardRadioItem>
            )}
            {verifiedPhoneLastFour && (
              <CardRadioItem
                Icon={MessageDotsCircle}
                title="Verify by text message"
                subtitle="We’ll text a code to your phone number on file:"
                value={InputType.PHONE}
              >
                <Typography $size="sm" $bold>
                  (***) ***-{verifiedPhoneLastFour}
                </Typography>
              </CardRadioItem>
            )}
            <Typography $size="sm" $color="textMuted">
              Standard messaging rates may apply.
            </Typography>
          </CardRadioRoot>
        )}
      />
      <Button type="submit" disabled={!isValid}>
        {isValid ? 'Continue' : 'Select Option'}
      </Button>
    </Box>
  )
}
