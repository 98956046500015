import { createFileRoute, redirect } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-adapter'
import { z } from 'zod'

import { ExistingAccount } from '@/containers/ExistingAccount'
import { detectExistingAccountQueryOptions } from '@/queries/registration/registration'

const schema = z.object({
  track_id: z.coerce.string(),
  email: z.coerce.string().optional(),
})

export const existingAccountSearchSchema = zodValidator(schema)
export type ExistingAccountSearchSchema = z.infer<typeof schema>

export const Route = createFileRoute('/setup/check-existing-account')({
  validateSearch: existingAccountSearchSchema,
  loaderDeps: ({ search }) => search,
  loader: async ({
    context: { auth, queryClient },
    deps: { track_id, email },
  }) => {
    const existingAccount = await queryClient.ensureQueryData(
      detectExistingAccountQueryOptions({ org_id: auth.orgId, track_id })
    )
    if (!existingAccount?.linked_orgs?.length) {
      redirect({
        to: '/setup/password-creation',
        state: state => ({
          ...state,
          email,
          track_id,
        }),
        throw: true,
      })
    }
  },
  component: ExistingAccount,
})
